import { SupportingDetailsEntry } from "frontend/contentful/schema/blocks";
import {
  SupportingDetails,
  SupportingDetailsProps,
} from "design-system/components/blocks/supporting-details/supporting-details";
import { createContentfulComponent } from "frontend/contentful/lib/create-contentful-component";
import { createMediaAssetProps } from "../primitives/media-asset.props";
import { RenderContentfulRichText } from "../utils/render-contentful-rich-text";
import { useContentful } from "frontend/hooks/use-contentful";
import { Alignment } from "design-system/types/types";

interface SupportingDetailsOptions {
  alignment?: Alignment;
  headerAbove?: boolean;
}

export const ContentfulSupportingDetails = createContentfulComponent<
  SupportingDetailsEntry,
  SupportingDetailsOptions
>(({ entry, options, createEditAttributes }) => {
  const { getEntries } = useContentful();

  const headerAbove = options?.headerAbove ?? false;

  const props: SupportingDetailsProps = {
    align: options?.alignment,
    HeadingLevel: headerAbove ? "h3" : "h2",
    mediaAsset: createMediaAssetProps(entry.fields.media),
    items: getEntries(entry.fields.listItems)?.map((item) => ({
      title: <RenderContentfulRichText document={item.fields?.title} />,
      subtitle: (
        <RenderContentfulRichText document={item.fields?.description} />
      ),
      editAttributes: {
        title: createEditAttributes({ entry: item, fieldId: "title" }),
        subtitle: createEditAttributes({ entry: item, fieldId: "description" }),
      },
    })),
  };

  return <SupportingDetails {...props} />;
});
